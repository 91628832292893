<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.archivedDeals") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :className="'w100'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                   <el-col :span="12" class="flex-style text-right">
                        <!-- <el-dropdown class="setting-cheek ml-2">
                        <el-button
                            class="padding_none"
                            size="small"
                            icon="el-icon-open"
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                            v-for="(column, index) in columns"
                            :key="'drop' + index"
                            >
                            <el-checkbox
                                :checked="column.show"
                                @change="check(column.column, $event)"
                                >{{ column.title }}
                            </el-checkbox>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown> -->
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart custom__scroll">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th>

                            <th v-if="columns.user.show">
                                {{ columns.user.title }}
                            </th>

                            <th v-if="columns.filial.show">
                                {{ columns.filial.title }}
                            </th>

                            <th v-if="columns.money_amount.show">
                                {{ columns.money_amount.title }}
                            </th>

                            <th v-if="columns.currency.show">
                                {{ columns.currency.title }}
                            </th>

                            <th v-if="columns.dealStage.show">
                                {{ columns.dealStage.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.name.show">
                                <crm-input
                                    :placeholder="columns.name.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.name"
                                ></crm-input>
                            </th>

                            <th v-if="columns.user.show">
                                <select-user
                                    :size="'mini'"
                                    :placeholder="$t('message.user')"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.user_id"
                                    v-model="filterForm.user_id"
                                >
                                </select-user>
                            </th>

                            <th v-if="columns.filial.show">
                                <select-filial
                                    :size="'mini'"
                                    :placeholder="columns.filial.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.filial_id"
                                    v-model="filterForm.filial_id"
                                >
                                </select-filial>
                            </th>

                            <th v-if="columns.money_amount.show">
                                <crm-input
                                    :placeholder="columns.money_amount.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.money_amount"
                                ></crm-input>
                            </th>

                            <th v-if="columns.currency.show">
                                 <select-currency
                                    :size="'mini'"
                                    :placeholder="columns.currency.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.currency_id"
                                    v-model="filterForm.currency_id"
                                ></select-currency>
                            </th>

                            <th v-if="columns.dealStage.show">
                               <select-deal-stage
                                    :size="'mini'"
                                    :placeholder="columns.dealStage.title"
                                    :id="filterForm.deal_stage_id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.deal_stage_id"
                                >
                                </select-deal-stage>
                            </th>

                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="deal in list"
                            :key="deal.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ deal.id }}
                            </td>

                            <td v-if="columns.name.show">
                                {{ deal.name }}
                            </td>

                            <td v-if="columns.user.show">
                                {{ deal.user ? deal.user.name : '' }}
                            </td>

                            <td v-if="columns.filial.show">
                                {{ deal.filial ? deal.filial.name : ''  }}
                            </td>

                            <td v-if="columns.money_amount.show">
                                {{ deal.money_amount }}
                            </td>

                            <td v-if="columns.currency.show">
                                {{ deal.currency ? deal.currency.symbol : ''  }}
                            </td>

                            <td v-if="columns.dealStage.show">
                                {{ deal.deal_stage ? deal.deal_stage.name : '' }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ deal.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ deal.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                               <el-dropdown @command="restoringDeal">
                                    <span class="el-dropdown-link more_icons">
                                    <i class="el-icon-arrow-down el-icon-more-outline"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" size="mini">      
                                        <el-dropdown-item :command="{action: 'show', id: deal.id}" icon="el-icon-view el-icon--left">       
                                            {{ $t('message.show') }}
                                        </el-dropdown-item>
                                        <el-dropdown-item :command="{action: 'restore', id: deal.id}" icon="el-icon-upload2 el-icon--left">
                                            {{ $t('message.restore') }}
                                        </el-dropdown-item>
                                        <!-- <el-dropdown-item :command="{action: 'delete', id: deal.id}" icon="el-icon-delete el-icon--left">
                                            {{ $t('message.delete') }}
                                        </el-dropdown-item> -->
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        @c-change="updatePagination"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <el-dialog :title="$t('message.deal')" :visible.sync="visibleShow" width="80%" @closed="empty()">
                <crm-show :deal_id="selectedItem" :open="visibleShow"></crm-show>
            </el-dialog>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import { mapGetters, mapActions, mapState } from "vuex";
import CrmShow from "./components/crm-show";
import selectUser from "@/components/inventory/select-users";
import selectDealStage from "@/components/inventory/select-dealStage";
import selectFilial from "@/components/inventory/select-filial";
import selectCurrency from "@/components/selects/select-currency";


export default {
    name: "archivedDeals",
    mixins: [list],
    components: {
        CrmShow,
        selectUser,
        selectFilial,
        selectDealStage,
        selectCurrency,
    },
    data() {
        return {
            visibleShow: false,
        };
    },

    computed: {
        ...mapGetters({
            list: "deals/archivedDeals",
            columns: "deals/columns",
            pagination: "deals/pagination",
            filter: "deals/filter",
            sort: "deals/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "deals/archivedDeals",
            setPagination: "deals/setPagination",
            updateSort: "deals/updateSort",
            updateFilter: "deals/updateFilter",
            updateColumn: "deals/updateColumn",
            updatePagination: "deals/updatePagination",
            show: "deals/show",
            empty: "deals/empty",
            delete: "deals/destroy",
            refreshData: "deals/refreshData",
            restoreDeal: "deals/restoreDeal"
        }),
        check: function (column, event) {
            this.updateColumn({ key: column, value: event });
        },
        restoringDeal(command){
            if(command.action === 'restore'){
                this.$confirm(
                    this.$t('message.do_you_really_want_to_do_this'),
                    this.$t("message.warning"),
                    {
                    confirmButtonText: this.$t("message.yes"),
                    cancelButtonText: this.$t("message.no"),
                    type: "warning",
                    }
                )
                .then(() => {
                    this.restoreDeal(command.id)
                        .then((res) =>{
                            this.fetchData()
                            this.$message({
                                type: "warning",
                                message: $t("message.deal_restored")
                            });
                        })
                        .catch((err) => {
                        })
                })
                .catch(() => {
                })
            }else if(command.action === 'delete'){
                this.$confirm(
                    this.$t('message.do_you_really_want_to_do_this'),
                    this.$t("message.warning"),
                    {
                    confirmButtonText: this.$t("message.yes"),
                    cancelButtonText: this.$t("message.no"),
                    type: "warning",
                    }
                )
                .then(() => {
                    this.delete(command.id)
                        .then(res => {
                            this.fetchData();
                            this.$message({
                                type: "warning",
                                message: $t("message.deal_deleted")
                            });
                        })
                        .catch(err => {
                        });
                })
                .catch(() => {
                })
                
            }else{
                this.visibleShow = true;
                this.selectedItem = command.id; 
            }
            
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('deals/EMPTY_ARCHIVED_DEALS_LIST');
        next()
    },
};
</script>

